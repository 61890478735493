<template>
  <div>
    <v-card width="6000" class="blue lighten-4">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div>{{ treatmentId == null ? 'New' : 'Edit' }} Treatments</div>
          <div class="subtitle-1">For Site: {{ siteDescription }}</div>
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="treatmentInfo">
        <div class="d-flex">
          <div>
            <v-card width="300" class="mr-2">
              <v-card-text>
                <DateTimePicker
                  v-model="treatmentInfo.WhenAdded"
                  label="When Added"
                  dense
                />
                <UserPicker
                  v-model="treatmentInfo.UserId"
                  label="Technician"
                  clearable
                  dense
                />
              </v-card-text>
            </v-card>
          </div>
          <div>
            <v-card width="830" class="ml-2">
              <v-card-text>
                <v-textarea
                  v-model="treatmentInfo.Notes"
                  label="Notes"
                  rows="2"
                  dense
                />
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="mt-4">
          <TreatmentPicker
            v-for="item in treatmentInfo.ItemList"
            :key="item.TreatmentTypeId"
            v-model="item.UnitsAdded"
            :label="item.Description"
            :max="item.HighestToAdd"
            :step="item.UnitIncrement"
            :caption="item.UnitDescription"
            @billable-input="billableInputChange(item, $event)"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="saveButtonClick" color="success">Save</v-btn>
        <v-btn @click="closeButtonClick" color="error">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import TreatmentPicker from '@/components/controls/TreatmentPicker'
import { host } from '@/services/HostAPI'

export default {
  name: 'TreatmentEditor',
  props: {
    treatmentId: {
      type: String,
      default: null
    },
    siteId: {
      type: String,
      required: true
    },
    siteDescription: {
      type: String,
      required: true
    },
    scheduledServiceId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { TreatmentPicker },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      treatmentInfo.value =
        props.treatmentId == null
          ? await newTemplateTreatmentInfo(
              props.siteId,
              props.scheduledServiceId
            )
          : await retrieveTreatmentInfo(props.treatmentId)
    }

    const newTemplateTreatmentInfo = async (siteId, scheduledServiceId) => {
      const rq = {
        Defaults: { SiteId: siteId, ScheduledServiceId: scheduledServiceId }
      }
      const rs = await host.treatment.newTemplate(rq)
      return rs.Info
    }

    const retrieveTreatmentInfo = async id => {
      const rq = { InfoId: id }
      const rs = await host.treatment.retrieve(rq)
      return rs.Info
    }

    const updateTreatmentInfo = async info => {
      const rq = { Info: info }
      const rs = await host.treatment.update(rq)
      return rs
    }

    const createTreatmentInfo = async info => {
      const rq = { Info: info }
      const rs = await host.treatment.create(rq)
      return rs
    }

    const treatmentInfo = ref()

    const closeButtonClick = () => {
      context.emit('close')
    }

    const saveButtonClick = () => {
      if (props.treatmentId == null) {
        doCreate()
      } else {
        doUpdate()
      }
    }

    const doCreate = async () => {
      const response = await createTreatmentInfo(treatmentInfo.value)
      if (response.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(response.Message)
      }
    }

    const doUpdate = async () => {
      const response = await updateTreatmentInfo(treatmentInfo.value)
      if (response.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(response.Message)
      }
    }

    const billableInputChange = (item, newVal) => {
      item.IsBillable = newVal
    }

    initDialog()

    return {
      dialogUtil,
      treatmentInfo,
      closeButtonClick,
      saveButtonClick,
      billableInputChange
    }
  }
}
</script>

<style lang="scss" scoped></style>
